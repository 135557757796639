
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';

  export default Vue.extend({
    name: 'es-subscription-payment-success-view',

    data: () => ({
      showAddPayment: false,
      selectedCard: null,
      isSubscriptionActivated: true,
      polling: 0,
      loading: false,
    }),

    computed: {
      ...mapGetters({
        getActiveSubscription: 'subscriptions/getActiveSubscription',
        getActivatedStatus: 'subscriptions/getActivatedStatus',
        getSelectedSubscription: 'subscriptions/getSelectedSubscription',
        isFetching: 'subscriptions/getIsFetching',
      }),
      getTermsAndConditions(): string {
        const { type, uses, monthly } = this.getSelectedSubscription;
        let termsText = 'subscription.terms.body';
        const period = monthly ? 'monthly' : 'yearly';

        if (type === 'FitnessSubscription') {
          termsText += `.fitness.${uses}`;
        } else if (type === 'BeautySubscription') {
          termsText += `.beauty.${period}`;
        } else if (type === 'MassageSubscription') {
          termsText += `.single_massage.${period}`;
        } else {
          termsText += `.couple_massage.${period}`;
        }

        return this.$t(termsText).toString();
      },
    },

    beforeDestroy() {
      clearInterval(this.polling);
    },

    methods: {
      onBack(): void {
        this.$router.push('/abonamente');
      },
    },
  });
